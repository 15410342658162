<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">General Settings</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >General form settings</span
        >
      </div>
      <div class="card-toolbar">
        <button type="reset" class="btn btn-success mr-2" @click="saveGeneralSetting">Save Changes</button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <b-row>
          <b-col xl="7" lg="10" md="8" sm="12">
            <b-form-group label="Select Saved General Setting" v-slot="{ ariaDescribedby }">
              <b-form-select
                v-model="activeSettingId"
                :options="savedInsurerSettings"
                :aria-describedby="ariaDescribedby"
                @change="onSettingChange"
                buttons
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="separator separator-dashed my-5"></div>
        <b-row class="form-group">
          <b-col xl="7" lg="10" md="8" sm="12">
            <b-form-group
              label="Setting Name"
            >
              <b-form-input
                v-model="generalSetting.settingName"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Text Verification:</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Enable</label
          >
          <div class="col-lg-8 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input 
                  type="checkbox" 
                  v-model="generalSetting.hasTextVerification" 
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row" v-if="generalSetting.hasTextVerification">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Text Local API Key</label
          >
          <div class="col-lg-8 col-xl-6">
            <b-form-input
              v-model="generalSetting.textLocalApiKey"
            ></b-form-input>
          </div>
        </div>
        <div class="form-group row" v-if="generalSetting.hasTextVerification">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Sender Name</label
          >
          <div class="col-lg-8 col-xl-6">
            <b-form-input
              v-model="generalSetting.senderName"
            ></b-form-input>
          </div>
        </div>

        <a :href="docPath+'TERMS_OF_BUSINESS.pdf'" target="_blank">Terms of Business</a>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
export default {
  name: "general-settings",
  data() {
    return {
      docPath: process.env.VUE_APP_RQ_BASE_URL+"docs/",
      activeSettingId: "",
      generalSetting: {
        _id: "",
        settingName: "",
        hasTextVerification: false,
        textLocalApiKey: "",
        senderName: ""
      }
    }
  },
  computed: {  
    userSetting: {
      get(){
        return this.$store.state.userSetting.userSetting;
      }
    },
    savedInsurerSettings: {
      get() {
        var settings = [{ 'text': 'Create New Setting', 'value' : '' }];
        
        if(this.userSetting && this.userSetting.generalSettings){
          this.userSetting.generalSettings.forEach(generalSetting => {
            settings.push({
              'text' : generalSetting.settingName,
              'value' : generalSetting._id
            });
          });
        }

        return settings;
      }
    }
  },
  methods: {
    saveGeneralSetting: function(){
      this.$store.dispatch("updateGeneralSetting", this.generalSetting).then(res => {
        this.$store.dispatch("getUserSetting")
        .then(() => {})
        .catch(() => {});


        var message = "Error saving.";
        var icon = "warning";

        if(res.data.success){
          message = "App settings saved.";
          icon = "success";

          this.activeSettingId = res.data.generalSettingId;
          this.generalSetting._id = res.data.generalSettingId;
        }

        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: icon,
          title: message
        });        
      });
    },
    onSettingChange: function(){
      console.log(this.activeSettingId);
      if(this.activeSettingId){
        if(this.$store.state.userSetting.userSetting && this.$store.state.userSetting.userSetting.generalSettings){
          this.$store.state.userSetting.userSetting.generalSettings.forEach(generalSetting => {
            if(generalSetting._id == this.activeSettingId){
              this.generalSetting = generalSetting;
            }
          });
        }
      } else {
        this.generalSetting = {
          _id: "",
          settingName: "",
          hasTextVerification: false,
          textLocalApiKey: "",
          senderName: ""
        }
      }
    },
  }
};
</script>
