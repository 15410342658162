<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          App Integration
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Manage app keys for embedding the plugin to your site</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="saveApps"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <div class="card-body">
        <p>Import this script to your site: <a :href="libPath" target="_blank">{{libPath}}</a></p>
        <p>Click <a :href="baseUrl+'docs/user-guide.pdf'" target="_blank">here</a> to view the guide on embedding the form to your site.</p>
        <div>
            <template v-for="(app,i) in apps">
                <app-key-item v-model="apps[i]" :key="i" :index="i"/>
            </template>
        </div>
        <div>
            <b-button variant="primary" @click="addApp">Add App</b-button>
        </div>
    </div>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import AppKeyItem from '@/view/pages/settings/AppKeyItem.vue';

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_RQ_BASE_URL || "",
      libPath: (process.env.VUE_APP_RQ_BASE_URL || "") + (process.env.VUE_APP_RQ_LIB_PATH || "")
    }
  },
  name: "app-setting",
  components: {
    AppKeyItem
  },
  computed: {
    apps: {
      get() {
        return this.userSetting.apps;
      },
      set(apps){
        let userSetting = { ...this.userSetting };
        userSetting.apps = apps;
        this.$store.commit("updateUserSetting", userSetting);
      }
    },
    userSetting: {
      get(){
        return this.$store.state.userSetting.userSetting;
      }
    }
  },
  methods: {
    addApp: function(){
      this.$store.commit("addApp");
    },
    saveApps: function(){
      this.$store.dispatch("updateUserSetting").then(success => {
        var message = "Error saving.";
        var icon = "warning";

        if(success){
          message = "App settings saved.";
          icon = "success";
        }

        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: icon,
          title: message
        });        
      });
    }
  },
  mounted: function(){
    this.$store.dispatch("getUserSetting")
      .then(() => {
      })
      .catch(() => {
      });
  }
}
</script>

<style>

</style>
