<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Insurer Settings</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Change your insurer specific settings</span
        >
      </div>
      <div class="card-toolbar">
        <button type="reset" class="btn btn-success mr-2" @click="saveInsurerSetting">Save Changes</button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <b-row>
          <b-col xl="7" lg="10" md="8" sm="12">
            <b-form-group label="Select Saved Insurer Setting" v-slot="{ ariaDescribedby }">
              <b-form-select
                v-model="activeSettingId"
                :options="savedInsurerSettings"
                :aria-describedby="ariaDescribedby"
                @change="onSettingChange"
                buttons
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="separator separator-dashed my-5"></div>
        <b-row class="form-group">
          <b-col xl="7" lg="10" md="8" sm="12">
            <b-form-group
              label="Setting Name"
            >
              <b-form-input
                v-model="insurerSetting.settingName"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Insurers:</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Display products only from this insurers:</label
          >
          <div class="col-lg-8 col-xl-6">
            <multiselect
              track-by="insurerId"
              v-model="insurers" 
              :options="insurerOptions"
              :multiple="true"
              label="name"
              placeholder="Select insurers"
              :close-on-select="false">
            </multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">NCD Setting:</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >NCD Uplift</label
          >
          <div class="col-lg-8 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input 
                  type="checkbox" 
                  v-model="insurerSetting.ncdUplift" 
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Default NCD Level</label
          >
          <div class="col-lg-8 col-xl-6">
            <b-form-select
              v-model="insurerSetting.ncdLevel"
              :options="ncdOptions"
              buttons
            ></b-form-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Promo:</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Enable Promo</label
          >
          <div class="col-lg-8 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input 
                  type="checkbox" 
                  v-model="insurerSetting.hasPromo" 
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div v-if="insurerSetting.hasPromo" class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Promo Text</label
          >
          <div class="col-lg-8 col-xl-6">
            <b-form-textarea rows="4" v-model="insurerSetting.defaultPromoText" placeholder='Enter promo text, use promo_price as placeholder for value.'></b-form-textarea>
          </div>
        </div>
        <div v-if="insurerSetting.hasPromo" class="form-group row">
          <div class="col-xl-4 col-lg-4 text-right">
            <b-button variant="success" @click="addPromoCondition">Add Condition +</b-button>
          </div>
          <div class="col-lg-8 col-xl-6">
            <b-row>
              <b-col sm="3" class="pr-0">
                <b-form-input
                  v-model="currentPromoCondition.min"
                  placeholder="Min Price"
                  ></b-form-input>
              </b-col>
              <b-col sm="3" class="pr-0">
                <b-form-input
                  v-model="currentPromoCondition.max"
                  placeholder="Max Price"
                  ></b-form-input>
              </b-col>
              <b-col sm="3" class="pr-0">
                <b-form-select
                  v-model="currentPromoCondition.type"
                  :options="promoTypeOptions"
                ></b-form-select>
              </b-col>
              <b-col v-if="currentPromoCondition.type == 'fixed-value'" sm="3" class="pr-0">
                <b-form-input
                  v-model="currentPromoCondition.fixedValue"
                  placeholder="Amount"
                ></b-form-input>
              </b-col>
              <b-col v-if="currentPromoCondition.type == 'rate'" sm="3" class="pr-0">
                <b-form-input
                  v-model="currentPromoCondition.rate"
                  placeholder="Rate"
                ></b-form-input>
              </b-col>
            </b-row>
            <template v-for="(promo,i) in insurerSetting.promoConditions">
              <b-row class="mt-5" :key="i">
                <b-col sm="1" md="2" lg="1">
                  <b-icon icon="trash" @click="deletePromoCondition(i)" variant="danger" role="button" font-scale="2"></b-icon>
                </b-col>
                <b-col sm="11" md="10" lg="11">
                  <a href="#" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{promo.min ? '£'+promo.min : 'Below'}} {{promo.min && promo.max ? ' - ':''}} {{promo.max ? '£'+promo.max : 'Above'}}</a>
                  <span v-if="promo.type == 'fixed-value'" class="text-muted font-weight-bold d-block">Fixed Amount - £{{promo.fixedValue || 0}}</span>
                  <span v-if="promo.type == 'rate'" class="text-muted font-weight-bold d-block">Rate - {{promo.rate || 0}}%</span>
                </b-col>
              </b-row>
            </template>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: "insurer-settings",
  components: {
    Multiselect
  },
  data() {
    return {
      activeSettingId: "",
      insurerSetting: {
        _id: "",
        settingName: "",
        ncdUplift: false,
        ncdLevel: 12,
        displayedInsurers: ['5|ALL','6|ALL','70|ALL','8|Essentials','8|Elite','9|ALL','18|ALL','22|ALL','11|ALL'],
        hasPromo: false,
        defaultPromoText: "",
        promoConditions: []
      },
      ncdOptions: [
        { text: "10", value: 10 },
        { text: "11", value: 11 },
        { text: "12", value: 12 },
        { text: "13", value: 13 },
      ],
      insurerOptions: [
        { name: 'WPA', insurerId: "5|ALL" },
        { name: 'AXAPP', insurerId: "6|ALL" },
        { name: 'Aviva', insurerId: "70|ALL" },
        { name: 'Freedom - Essentials', insurerId: "8|Essentials" },
        { name: 'Freedom - Elite', insurerId: "8|Elite" },
        { name: 'Vitality', insurerId: "9|ALL" },
        { name: 'Exeter', insurerId: "18|ALL" },
        { name: 'BUPA', insurerId: "22|ALL" },
        { name: 'National Friendly', insurerId: "11|ALL" }
      ],
      promoTypeOptions: [
        { text: "Fixed", value: "fixed-value" },
        { text: "Percent", value: "rate" }
      ],
      currentPromoCondition: {
        min: null,
        max: null,
        type: 'fixed-value',
        rate: null,
        fixedValue: null
      }
    }
  },
  computed: {  
    test: {
      get(){
        return this.selectedInsurerss;
      },
      set(values){
        console.log(values);
        this.selectedInsurerss = values;
      }
    },
    userSetting: {
      get(){
        return this.$store.state.userSetting.userSetting;
      }
    },
    savedInsurerSettings: {
      get() {
        var settings = [{ 'text': 'Create New Setting', 'value' : '' }];
        
        if(this.userSetting && this.userSetting.insurerSettings){
          this.userSetting.insurerSettings.forEach(insurerSetting => {
            settings.push({
              'text' : insurerSetting.settingName,
              'value' : insurerSetting._id
            });
          });
        }

        return settings;
      }
    },
    insurers: {
      get(){
        var insurerData = {
          "5|ALL": { name: 'WPA', insurerId: "5|ALL" },
          "6|ALL": { name: 'AXAPP', insurerId: "6|ALL" },
          "70|ALL": { name: 'Aviva', insurerId: "70|ALL" },
          "8|Essentials": { name: 'Freedom - Essentials', insurerId: "8|Essentials" },
          "8|Elite": { name: 'Freedom - Elite', insurerId: "8|Elite" },
          "9|ALL": { name: 'Vitality', insurerId: "9|ALL" },
          "18|ALL": { name: 'Exeter', insurerId: "18|ALL" },
          "22|ALL": { name: 'BUPA', insurerId: "22|ALL" },
          "11|ALL": { name: 'National Friendly', insurerId: "11|ALL" },
        }
        var selectedInsurers = [];

        this.insurerSetting.displayedInsurers.forEach(objIndex => {
          if(insurerData[objIndex]){
            selectedInsurers.push(insurerData[objIndex]);
          }
        });
        return selectedInsurers;
      },
      set(value){
        var insurerIds = [];
        value.forEach(obj => {
          insurerIds.push(obj.insurerId);
        })
        this.insurerSetting.displayedInsurers = insurerIds;
      }
    }
  },
  methods: {
    addPromoCondition: function(){
      this.insurerSetting.promoConditions.push(Object.assign({},this.currentPromoCondition));
      this.currentPromoCondition = {
        min: null,
        max: null,
        type: 'fixed-value',
        rate: null,
        fixedValue: null
      };
    },
    deletePromoCondition: function(index){
      this.insurerSetting.promoConditions.splice(index, 1);
    },
    saveInsurerSetting: function(){
      this.$store.dispatch("updateInsurerSetting", this.insurerSetting).then(res => {
        this.$store.dispatch("getUserSetting")
        .then(() => {})
        .catch(() => {});


        var message = "Error saving.";
        var icon = "warning";

        if(res.data.success){
          message = "App settings saved.";
          icon = "success";

          this.activeSettingId = res.data.insurerSettingId;
          this.insurerSetting._id = res.data.insurerSettingId;
        }

        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: icon,
          title: message
        });        
      });
    },
    onSettingChange: function(){
      console.log(this.activeSettingId);
      if(this.activeSettingId){
        if(this.$store.state.userSetting.userSetting && this.$store.state.userSetting.userSetting.insurerSettings){
          this.$store.state.userSetting.userSetting.insurerSettings.forEach(insurerSetting => {
            if(insurerSetting._id == this.activeSettingId){
              this.insurerSetting = insurerSetting;
            }
          });
        }
      } else {
        this.insurerSetting = {
          _id: "",
          settingName: "",
          ncdUplift: false,
          ncdLevel: 12,
          displayedInsurers: ['5|ALL','6|ALL','70|ALL','8|Essentials','8|Elite','9|ALL','18|ALL','22|ALL','11|ALL'],
          hasPromo: false,
          defaultPromoText: "",
          promoConditions: []
        }
      }
    },
  }
};
</script>
