<template>
  <div class="app-key-item pb-4 pt-4">
    <b-row>
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description=""
          label="App Name"
        >
          <b-form-input v-model="identifier"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description="This will be used in embedding the plugin in your site."
          label="APP Key"
        >
          <b-input-group>
            <b-form-input v-model="appKey" readonly></b-form-input>
            <b-input-group-append>
              <b-button variant="secondary" @click="generateAppKey">
                <b-icon icon="arrow-repeat" variant="success" font-scale="1"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description="This will be used in embedding the plugin in your site. Separate domains by comma."
          label="Domain whitelist"
        >
          <b-form-textarea
            v-model="domainList"
            placeholder="Enter domains separated by comma"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description=""
          label="Push Leads to CRM"
        >
          <span class="switch switch-sm">
            <label>
              <input 
                type="checkbox" 
                v-model="pushLeads" 
              />
              <span></span>
            </label>
          </span>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="pushLeads">
      <b-col xl="3" lg="4" md="2" sm="12">
        <b-form-select
            v-model="crmEndpoint"
            :options="crmEndpointOptions"
            buttons
        ></b-form-select>
      </b-col>
      <b-col xl="4" lg="6" md="5" sm="12">
        <b-form-input 
          v-model="crmApiKey"
          placeholder="Type user's API key"
          :disabled="crmEndpoint == 'none'"
          />
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="pushLeads">
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description=""
          label="Custom Tags"
        >
          <b-form-tags
            tag-variant="success"
            v-model="customTags"
            separator=","
            placeholder="Enter new tags separated by comma2"
            no-add-on-enter
          ></b-form-tags>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description=""
          label="Form Type"
        >
          <b-form-select
              v-model="formType"
              :options="formTypeOptions"
              buttons
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description=""
          label="Color Theme"
        >
          <b-form-select
              v-model="colorSetting"
              :options="colorList"
              buttons
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description=""
          label="Email Setting"
        >
          <b-form-select
              v-model="emailSetting"
              :options="emailSettingList"
              buttons
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description=""
          label="Insurer Setting"
        >
          <b-form-select
              v-model="insurerSetting"
              :options="insurerSettingList"
              buttons
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7" lg="10" md="8" sm="12">
        <b-form-group
          description=""
          label="General Setting"
        >
          <b-form-select
              v-model="generalSetting"
              :options="generalSettingList"
              buttons
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-col xl="1" lg="1" md="1" sm="12">
      <b-icon icon="trash" variant="danger" v-on:click="removeAppKey(index)" role="button" font-scale="2"></b-icon>
    </b-col>
    <div class="separator separator-dashed my-5"></div>
  </div>
</template>

<script>
export default {
  name: "app-key-item",
  props: ["value", "index"],
  data() {
    return {
      crmEndpointOptions: [
        { text: "Select Endpoint", value: "none", disabled: true },
        { text: "MovoCRM", value: "movo" },
        { text: "RapidCRM", value: "rapid" },
      ],
      formTypeOptions: [
        { text: 'PMI Quote', value: 'pmi' },
        { text: 'Life Quote', value: 'life' }
      ]
    }
  },
  computed: {
    appKey: {
      get(){
        return this.value.appKey;
      },
      set(appKey){
        var app = { ...this.value };
        app.appKey = appKey;
        this.$emit("input", app);
      }
    },
    identifier: {
      get(){
        return this.value.identifier;
      },
      set(identifier){
        var app = { ...this.value };
        app.identifier = identifier;
        this.$emit("input", app);
      }
    },
    formType: {
      get(){
        return this.value.formType;
      },
      set(type){
        var app = { ...this.value };
        app.formType = type;
        this.$emit("input", app);
      }
    },
    colorSetting: {
      get(){
        return this.value.colorSetting;
      },
      set(colorSetting){
        var app = { ...this.value };
        app.colorSetting = colorSetting;
        this.$emit("input", app);
      }
    },
    emailSetting: {
      get(){
        return this.value.emailSetting;
      },
      set(emailSetting){
        var app = { ...this.value };
        app.emailSetting = emailSetting;
        this.$emit("input", app);
      }
    },
    insurerSetting: {
      get(){
        return this.value.insurerSetting;
      },
      set(insurerSetting){
        var app = { ...this.value };
        app.insurerSetting = insurerSetting;
        this.$emit("input", app);
      }
    },
    generalSetting: {
      get(){
        return this.value.generalSetting;
      },
      set(generalSetting){
        var app = { ...this.value };
        app.generalSetting = generalSetting;
        this.$emit("input", app);
      }
    },
    colorList: {
      get() {
        var settings = [];
        
        this.$store.state.formDesign.colorSettingList.forEach(colorSetting => {
          settings.push({
            'text' : colorSetting.settingName,
            'value' : colorSetting._id
          });
        });

        return settings;
      }
    },
    emailSettingList: {
      get() {
        var settings = [];
        
        this.$store.state.userSetting.userSetting.emailNotifications.forEach(email => {
          settings.push({
            'text' : email.settingName,
            'value' : email._id
          });
        });

        return settings;
      }
    },
    insurerSettingList: {
      get() {
        var settings = [];
        
        this.$store.state.userSetting.userSetting.insurerSettings.forEach(insurerSetting => {
          settings.push({
            'text' : insurerSetting.settingName,
            'value' : insurerSetting._id
          });
        });

        return settings;
      }
    },
    generalSettingList: {
      get() {
        var settings = [];
        
        this.$store.state.userSetting.userSetting.generalSettings.forEach(generalSetting => {
          settings.push({
            'text' : generalSetting.settingName,
            'value' : generalSetting._id
          });
        });

        return settings;
      }
    },
    domainList: {
      get(){
        return this.value.domains.join(",")
      },
      set(domains){
        var domainList = domains.split(",").map(str => str.replace(/\s/g, ''));
        var app = { ...this.value };
        app.domains = domainList;
        this.$emit("input", app);
      }
    },
    pushLeads: {
      get(){
        return this.value.pushLeads;
      },
      set(pushLeads){
        var app = { ...this.value };
        app.pushLeads = pushLeads;
        this.$emit("input", app);
      }
    },
    crmApiKey: {
      get(){
        return this.value.crmApiKey;
      },
      set(crmApiKey){
        var app = { ...this.value };
        app.crmApiKey = crmApiKey;
        this.$emit("input", app);
      }
    },
    crmEndpoint: {
      get(){
        return this.value.crmEndpoint;
      },
      set(crmEndpoint){
        var app = { ...this.value };
        app.crmEndpoint = crmEndpoint;
        this.$emit("input", app);
      }
    },
    customTags: {
      get(){
        return this.value.customTags;
      }, 
      set(customTags){
        var app = { ...this.value };
        app.customTags = customTags;
        this.$emit("input", app);
      }
    }
  },
  methods: {
    removeAppKey: function(appIndex){
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit("removeApp", appIndex);
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: "App removed."
          });   
        }
      });
    },
    generateAppKey: function(){
      const keyLength = 32;

      var i, key = "", characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var charactersLength = characters.length;

      for (i = 0; i < keyLength; i++) {
        key += characters.substr(Math.floor((Math.random() * charactersLength) + 1), 1);
      }

      this.appKey = key;
    }
  },
  mounted: function(){
    if(!this.appKey){
      this.generateAppKey();
    }
    this.$store.dispatch("getColorSettingList");
  }
}
</script>

<style lang="scss">
.app-key-item {
  // border-bottom: solid 1px #F8F8F8;
}
</style>