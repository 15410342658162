<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Email Settings</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Change your email settings</span
        >
      </div>
      <div class="card-toolbar">
        <button type="reset" class="btn btn-success mr-2" @click="saveEmailSetting">Save Changes</button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <b-row>
          <b-col xl="7" lg="10" md="8" sm="12">
            <b-form-group label="Select Saved Email Setting" v-slot="{ ariaDescribedby }">
              <b-form-select
                v-model="activeSettingId"
                :options="savedEmailSettings"
                :aria-describedby="ariaDescribedby"
                @change="onSettingChange"
                buttons
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="separator separator-dashed my-5"></div>
        <b-row class="form-group">
          <b-col xl="7" lg="10" md="8" sm="12">
            <b-form-group
              label="Setting Name"
            >
              <b-form-input
                v-model="emailSetting.settingName"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Email Server:</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Use custom email server</label
          >
          <div class="col-lg-8 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input 
                  type="checkbox" 
                  v-model="emailSetting.customEmailServer" 
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div v-if="emailSetting.customEmailServer">
          
          <b-row class="form-group mb-0">
            <b-col xl="7" lg="10" md="8" sm="12">
              <b-form-group
                label="Gmail Server"
              >
                <toggle-button
                  v-model="emailSetting.isGmail"
                  :sync="true"
                />  
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="form-group mb-0" v-if="!emailSetting.isGmail">
            <b-col xl="7" lg="10" md="8" sm="12">
              <b-form-group
                label="SMTP Server"
              >
                <b-form-input
                  v-model="emailSetting.smtpHost"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="form-group mb-0" v-if="!emailSetting.isGmail">
            <b-col xl="7" lg="10" md="8" sm="12">
              <b-form-group
                label="SMTP Port"
              >
                <b-form-input
                  v-model="emailSetting.smtpPort"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="form-group mb-0">
            <b-col xl="7" lg="10" md="8" sm="12">
              <b-form-group
                label="Username"
              >
                <b-form-input
                  v-model="emailSetting.smtpUsername"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="form-group mb-0">
            <b-col xl="7" lg="10" md="8" sm="12">
              <b-form-group
                label="Password"
              >
                <b-form-input
                  type="password"
                  v-model="emailSetting.smtpPassword"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="form-group mb-0">
            <b-col xl="7" lg="10" md="8" sm="12">
              <b-form-group
                label="Sender Name"
              >
                <b-form-input
                  v-model="emailSetting.smtpSenderName"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="form-group mb-3">
            <b-col xl="3" lg="3" md="3" sm="12">
              <b-button class="w-100" variant="warning" :disabled="testEmailLoading" @click="testEmail">
                <b-spinner small class="mr-3" v-if="testEmailLoading"></b-spinner>
                <span>Test Email</span>
              </b-button>
            </b-col>
            <b-col xl="9" lg="9" md="9" sm="12">
              <b-alert
                :show="dismissCountDown"
                dismissible
                :variant="testEmailClass"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                <span>{{testEmailMessage}}</span>
              </b-alert>
            </b-col>
          </b-row>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Setup Activity Notification:</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Send Quotes</label
          >
          <div class="col-lg-8 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input
                  type="checkbox"
                  v-model="emailSetting.quoteNotification"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <b-row class="form-group" v-if="emailSetting.quoteNotification">
          <b-col xl="7" lg="10" md="8" sm="12">
            <b-form-group
              description="Enter emails you want to receive the notifications. Separate emails by comma."
              label="Send quote notifications to the following email:"
            >
              <b-form-textarea
                v-model="quoteEmails"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Send Applications</label
          >
          <div class="col-lg-8 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input 
                  type="checkbox" 
                  v-model="emailSetting.applicationNotification" 
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <b-row class="form-group" v-if="emailSetting.applicationNotification">
          <b-col xl="7" lg="10" md="8" sm="12">
            <b-form-group
              description="Enter emails you want to receive the notifications. Separate emails by comma."
              label="Send application notifications to the following email:"
            >
              <b-form-textarea
                v-model="applicationEmails"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="separator separator-dashed my-10"></div>
        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Customer Confirmation Email:</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Link to modify quote</label
          >
          <div class="col-lg-8 col-xl-6">
            <b-form-input
              v-model="emailSetting.linkBack"
            ></b-form-input>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Send quote confirmation email</label
          >
          <div class="col-lg-8 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input 
                  type="checkbox" 
                  v-model="emailSetting.quoteConfirmationEmail" 
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <b-row class="form-group" v-if="emailSetting.quoteConfirmationEmail">
          <b-col xl="10" lg="10" md="10" sm="12">
            <b-form-group
              description=""
              label="Compose message to be sent to customer on quote:"
            >
              <quill-editor
                :style="quillStyle"
                v-model="emailSetting.quoteConfirmationMessage"
                :options="editorOption" />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Send application confirmation email</label
          >
          <div class="col-lg-8 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input 
                  type="checkbox" 
                  v-model="emailSetting.applicationConfirmationEmail" 
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <b-row class="form-group" v-if="emailSetting.applicationConfirmationEmail">
          <b-col xl="10" lg="10" md="10" sm="12">
            <b-form-group
              description=""
              label="Compose message to be sent to customer on application:"
            >
              <div id="toolbar">
                <button class="ql-bold"></button>    
              </div>
              <quill-editor
                :style="quillStyle"
                v-model="emailSetting.applicationConfirmationMessage"
                :options="editorOption" />
            </b-form-group>
          </b-col>
        </b-row>
        
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';

export default {
  name: "email-settings",
  components: {
    quillEditor
  },
  data() {
    return {
      testEmailLoading: false,
      testEmailClass: '',
      dismissSecs: 5,
      dismissCountDown: 0,
      testEmailMessage: "",
      editorOption: {
        modules: {
          toolbar: { 
            container: [
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['bold', 'italic', 'underline'],
              ['link'], 
              [{ 'align': [] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'placeholder' : ['{{FIRST_NAME}}','{{LAST_NAME}}','{{TITLE}}','{{QUOTE_REFERENCE}}','{{RESULTS}}'] }]
            ],
            handlers: {
              'placeholder': function(value){
                if (value) {
                  const cursorPosition = this.quill.getSelection().index;
                  this.quill.insertText(cursorPosition, value);
                  this.quill.setSelection(cursorPosition + value.length);
                }
              }
            } 
          }
        },
        theme: 'snow'
      },
      quillStyle: {
        height: "200px"
      },
      activeSettingId: "",
      emailSetting: {
        _id: "",
        settingName: "",
        quoteNotification: false,
        applicationNotification: false,
        quoteEmailList: [],
        applicationEmailList: [],
        quoteConfirmationEmail: false,
        applicationConfirmationEmail: false,
        quoteConfirmationMessage: "",
        applicationConfirmationMessage: "",
        customEmailServer: false,
        smtpSenderName: "",
        linkBack: "",
        smtpHost: "",
        smtpPort: "",
        smtpUsername: "",
        smtpPassword: "",
        isGmail: false
      }
    }
  },
  computed: {  
    quoteEmails: {
      get(){
        return this.emailSetting.quoteEmailList.join(",");
      },
      set(emails){
        var emailList = emails.split(",").map(str => str.replace(/\s/g, ''));
        this.emailSetting.quoteEmailList = emailList;
      }
    },
    applicationEmails: {
      get(){
        return this.emailSetting.applicationEmailList.join(",");
      },
      set(emails){
        var emailList = emails.split(",").map(str => str.replace(/\s/g, ''));
        this.emailSetting.applicationEmailList = emailList;
      }
    },
    userSetting: {
      get(){
        return this.$store.state.userSetting.userSetting;
      }
    },
    savedEmailSettings: {
      get() {
        var settings = [{ 'text': 'Create New Setting', 'value' : '' }];
        
        if(this.$store.state.userSetting.userSetting && this.$store.state.userSetting.userSetting.emailNotifications){
          this.$store.state.userSetting.userSetting.emailNotifications.forEach(emailSetting => {
            settings.push({
              'text' : emailSetting.settingName,
              'value' : emailSetting._id
            });
          });
        }

        return settings;
      }
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    testEmail: function(){
      var email = {
        isGmail: this.emailSetting.isGmail,
        smtpHost: this.emailSetting.smtpHost,
        smtpPassword: this.emailSetting.smtpPassword,
        smtpPort: this.emailSetting.smtpPort,
        smtpSenderName: this.emailSetting.smtpSenderName,
        smtpUsername: this.emailSetting.smtpUsername
      }
      this.testEmailLoading = true;
      this.$store.dispatch('testEmailSetting', email).then(res => {
        if(res.data.success){
          this.testEmailClass = 'success';
        } else {
          this.testEmailClass = 'danger';
        }
        
        this.testEmailMessage = res.data.message;
        this.dismissCountDown = this.dismissSecs;

        this.testEmailLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.testEmailClass = 'danger';
        this.testEmailMessage = "Something went wrong.";
        this.dismissCountDown = this.dismissSecs;

        this.testEmailLoading = false;
      });
    },
    saveEmailSetting: function(){
      this.$store.dispatch("updateEmailSetting", this.emailSetting).then(res => {
        this.$store.dispatch("getUserSetting")
        .then(() => {})
        .catch(() => {});


        var message = "Error saving.";
        var icon = "warning";

        if(res.data.success){
          message = "App settings saved.";
          icon = "success";

          this.activeSettingId = res.data.emailSettingId;
          this.emailSetting._id = res.data.emailSettingId;
        }

        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: icon,
          title: message
        });        
      });
    },
    onSettingChange: function(){
      console.log(this.activeSettingId);
      if(this.activeSettingId){
        if(this.$store.state.userSetting.userSetting && this.$store.state.userSetting.userSetting.emailNotifications){
          this.$store.state.userSetting.userSetting.emailNotifications.forEach(emailSetting => {
            if(emailSetting._id == this.activeSettingId){
              this.emailSetting = emailSetting;
            }
          });
        }
      } else {
        this.emailSetting = {
          _id: "",
          settingName: "",
          quoteNotification: false,
          applicationNotification: false,
          quoteEmailList: [],
          applicationEmailList: [],
          quoteConfirmationEmail: false,
          applicationConfirmationEmail: false,
          quoteConfirmationMessage: "",
          applicationConfirmationMessage: "",
          customEmailServer: "",
          smtpHost: "",
          smtpPort: "",
          smtpUsername: "",
          smtpPassword: "",
          isGmail: false
        }
      }
    },
  },
  updated(){
    // We need to manually supply the HTML content of our custom dropdown list
    const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));
    placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
  }
};
</script>
