<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Settings Overview
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          ></span
        >
      </div>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <div class="card-body">
        
    </div>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
export default {
  data() {
    return {}
  },
  name: "settings-overview",
  components: {},
  computed: {
    
  },
  methods: {
    
  },
  mounted: function(){}
}
</script>

<style>

</style>
